import React, { useEffect } from 'react';
import styles from './HamburgerMenuMobile.module.scss';


const HamburgerMenuMobile = ({ cdn, isShow, handleClosePopup, functionArray, activeTab}) => {
  const btnClose = cdn + 'btn-close.png';

  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible"
    }
  }, [isShow])

  const menuData = [
    { id: 0, headerText: 'ลงทะเบียนล่วงหน้า',index: 'section1' },
    { id: 1, headerText: 'รางวัลลงทะเบียน',index: 'section2' },
    { id: 2, headerText: 'กิจกรรมล่าขุมทรัพย์',index: 'section3' },
    { id: 3, headerText: 'แนะนำตัวละคร',index: 'section4' },
    { id: 4, headerText: 'แนะนำระบบ',index: 'section5' },
    { id: 5, headerText: 'Facebook Fanpage',index: 'none' },
    { id: 6, headerText: 'Official Discord',index: 'none' },
    { id: 7, headerText: 'Official Facebook Group',index: 'none' },
    { id: 8, headerText: 'ข้อกำหนดและเงื่อนไข',index: 'none' },
    { id: 9, headerText: 'คุ้มครองข้อมูลส่วนบุคคล',index: 'none' },
    { id: 10, headerText: 'ติดต่อเรา',index: 'none' },
  ];

  return (
    <div className={`${styles.overlayBackground} ${isShow ? styles.show : ''}`}>
      <div className={styles.popOutContainer}>
      <img className={styles.btnClose} src={btnClose} alt='btn-close' onClick={handleClosePopup} />
        <div className={styles.menuContent}>
          {menuData.map((data,index)=>{
            return (
                <div className={`${styles.hamburgerMenu} ${activeTab === data.index ? styles.active : ''}`} onClick={functionArray[index]}>{data.headerText}</div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenuMobile;
