import React, { useState,useEffect } from 'react';
import styles from './InfoCarousel.module.scss';
import ImageButton from './Button';
import IconVideoPlayer from './IconVideoPlayer';
import handleGAevent from '../helper/analytic';
import ReactPixel from 'react-facebook-pixel';

const InfoCarousel = ({ cdn,isShow , handleClosePopup, selectedIndex, setSelectedIndex, setIsVideoPlaying}) => {
    const infoContainer = [
        {id : 1, thumbnail : 'https://sko-cdn.zoltangames.com/preregister/img-thumbnail-play-button.png',videoLink : 'https://youtu.be/pXpIo-bYHz0',headText : 'img-headertext-system-info-1', subText : '“วางกลยุทธ์จัดทัพขุนพลที่แข็งแกร่งเหนือขีดจำกัดสู่สมรภูมิรบ เพื่อชิงความเป็นหนึ่งในใต้หล้า”', info : 'เริ่มต้นพัฒนากองทัพเพื่อเสริมความแข็งแกร่ง ด้วยสารพัดวิธีการเพื่อสร้างกองกำลังที่ดีที่สุด อาทิ ระบบพัฒนาเลเวลแม่ทัพ ระบบสวมใส่อาวุธและเครื่องประดับ ระบบการอัปเกรดสกิล ระบบเปลี่ยนอาชีพ ระบบ Awake และยังมีระบบอีกมากมายที่จะทำให้กองทัพของท่านสามารถเติบโตและแข็งแกร่งได้อย่างไม่มีที่สิ้นสุด'},
        {id : 2, thumbnail : 'https://sko-cdn.zoltangames.com/preregister/img-thumbnail-play-button.png',videoLink : 'https://youtu.be/lH_JLV0Jgis',headText : 'img-headertext-system-info-2', subText : ' “ศึกชิงชัยแดนลับผลึกม่วง เปิดศึกช่วงชิงทรัพยากรสุดล้ำค่ากับแม่ทัพทั่วดินแดน”', info : 'พบกับสงครามช่วงชิงทรัพยากรอันล้ำค่าของนักรบทั่วดินแดน โดยทุกท่านสามารถวางกลยุทธ์จัดทัพที่แข็งแกร่งที่สุดเพื่อมาพิชิตสิทธ์ในการครอบครองผลึกหายากเหล่านี้กับผู้เล่นแบบข้ามเซิฟเวอร์ เพื่อรับคะแนนจัดอันดับและผลตอบแทนเป็นผลึกสีต่าง ๆ ตามเวลาที่กำหนด โดยจะมีพื้นที่สนามรบสุดท้าทายให้เลือกมากถึง 350 ชั้น ซึ่งท่านแม่ทัพสามารถนำผลึกที่ได้รับไปซื้อสินค้าจากร้านค้าผลึกม่วง เพื่อรับเครื่องประดับขุนพล และไอเทมหายากต่าง ๆ ที่มีจำหน่ายเพียงที่ร้านค้าผลึกม่วงเท่านั้น'},
        {id : 3, thumbnail : 'https://sko-cdn.zoltangames.com/preregister/img-thumbnail-play-button.png',videoLink : 'https://youtu.be/h_pdRoXCVho',headText : 'img-headertext-system-info-3', subText : '“จัดทัพผจญภัยฟื้นฟูดินแดนสามก๊ก”', info : 'ผจญภัยในดินแดนสามก๊ก วางกลยุทธ์จัดทัพต่อสู้กับศัตรูเพื่อปราบหัวเมืองต่าง ๆ และรับทรัพยากรสุดล้ำค่าจากเมืองนั้น ๆ เป็นรางวัลที่จะได้รับอย่างต่อเนื่อง เพื่อนำมาพัฒนาขุนพลให้แกร่งที่สุด ยิ่งยึดครองเมืองได้เยอะเท่าไหร่ รางวัลก็จะยิ่งดีขึ้นเท่านั้น โดยของรางวัลมีตั้งแต่เหรียญทองแดง ของอัพเกรดขุนพล ไปจนถึงชิ้นส่วนขุนพลเลยทีเดียว'},
        {id : 4, thumbnail : 'https://sko-cdn.zoltangames.com/preregister/img-thumbnail-play-button.png',videoLink : 'https://youtu.be/veOvGaeX2KM',headText : 'img-headertext-system-info-4', subText : '“สงครามที่ไม่มีมิตรแท้และศัตรูที่ถาวร ร่วมต่อสู้กับสหายรบ เพื่อเป็นใหญ่ในแผ่นดิน”', info : 'พบกับระบบสงครามสุดยิ่งใหญ่และท้าทาย ระเบิดศึกความสนุกกับสนามรบที่ไม่มีมิตรแท้และศัตรูถาวรจากระบบการสุ่มพันธมิตรในทุกฤดูกาล ร่วมมือร่วมใจกับสหายร่วมพันธมิตรในแต่ละรอบเพื่อแย่งชิงบัลลังก์จักรพรรดิ์และครองอันดับที่สูงที่สุดในใต้หล้า แต่ในอนาคตข้างหน้าจะเป็นเช่นไร คงต้องให้เป็นเรื่องของโชคชะตาที่จะกำหนดอีกครั้งหนึ่ง'}
    ]
    const [currentPage, setCurrentPage] = useState(selectedIndex);

    useEffect(() => {
        if (selectedIndex !== currentPage) {
          setCurrentPage(selectedIndex);
        }
      }, [currentPage, selectedIndex]);

  const handlePageIncrease =  () => {
    handleGAevent('Info-Carousel', 'Click_Next_Page_Info_Carousel', `Click Next Page on Info Carousel`);
    ReactPixel.trackCustom('ClickNextPageInfoCarousel');
    if(currentPage  < 3){
      setCurrentPage(prevPage => {
        const nextPage = prevPage + 1;
        setSelectedIndex(nextPage); 
        return nextPage;
      })
    }else{
      setCurrentPage(0);
      setSelectedIndex(0); 
    };
  }

  const handlePageDecrease=  () => {
    handleGAevent('Info-Carousel', 'Click_Previous_Page_Info_Carousel', `Click Previous Page on Info Carousel`);
    ReactPixel.trackCustom('ClickPreviousPageInfoCarousel');
    if(currentPage  > 0){
      setCurrentPage(prevPage => {
        const nextPage = prevPage - 1;
        setSelectedIndex(nextPage); 
        return nextPage;
      });
    }else{
      setCurrentPage(3);
      setSelectedIndex(3); 
    }
  }
  const popoutBackground = {
    width : '80%',
    height : '70%',

  };

  const buttonStyle = {
    width : '1.5vw',
    height : '1.5vw',
    position : 'absolute',
    top : '11%',
    right : '11%'
  };
  const leftArrowStyle = {
    width: '3%', 
    cursor: 'pointer',
    position : 'absolute',
    top : '50%',
    left : '5%'
  };
  const rightArrowStyle = {
    width: '3%', 
    cursor: 'pointer',
    position : 'absolute',
    top : '50%',
    right : '5%'
  };

  const thumbnailStyle = {
    width : 'auto',
    height: '60%'
  }
  

  return (
    <div className={`${styles.overlayBackground} ${isShow ? styles.show : ''}`}>
      <div className={styles.carouselContainer}>
        <ImageButton
          onClick={handleClosePopup}
          iconSrc="btn_close.png"
          altText="Icon"
          additionalButtonStyle={buttonStyle}
        />
        <div className={styles.popUpContent}>
          <img className={styles.headerText} src={`https://sko-cdn.zoltangames.com/preregister/${infoContainer[currentPage].headText}.png`} alt="" />
          <IconVideoPlayer
            cdn={cdn}
            videoSrc={infoContainer[currentPage].videoLink}
            thumbnailSrc={infoContainer[currentPage].thumbnail}
            thumbnailWidth='90%'
            thumbnailHeight='35%'
            thumbnailStyle={thumbnailStyle}
            setIsVideoPlaying={setIsVideoPlaying}
          ></IconVideoPlayer>
          <div className={styles.textContainer}>
            <div className={styles.subHeaderText}>
              {infoContainer[currentPage].subText}
            </div>
            <div className={styles.infoText}>
              {infoContainer[currentPage].info}
            </div>
          </div>
        </div>
      </div>
      <ImageButton
        onClick={handlePageDecrease}
        iconSrc="img-left-arrow-yellow.png"
        altText="Icon"
        additionalButtonStyle={leftArrowStyle}
      />
      <ImageButton
        onClick={handlePageIncrease}
        iconSrc="img-right-arrow-yellow.png"
        altText="Icon"
        additionalButtonStyle={rightArrowStyle}
      />
    </div>
  );
};

export default InfoCarousel;