import styles from './PcSection3.module.scss';
import { useState } from 'react';
import ImageButton from '../../components/Button';
import { CHARACTER_DATA } from '../../consts/characterData';
import IconVideoPlayer from '../../components/IconVideoPlayer';

const PcSection3 = ({ cdn,sectionRef, contentHeight , setIsMenuShow }) => {
    // -- headtext
    const headtext = `https://sko-cdn.zoltangames.com/preregister/img-headtext-page-4-v2.png`;

    // -- Sub Header frame
    const subHeaderframe = {
        backgroundImage: `url(https://sko-cdn.zoltangames.com/preregister/bg-header-frame-page-4-v2.png)`
    };

    // -- Characte selection state
    const [selectedCharacter, setselectedCharacter] = useState(CHARACTER_DATA[0]);
    // -- Character List Array
    const characterButtonArray = CHARACTER_DATA.map((data, index) => {
        const dynamicStyle = {
            width: '60%',
        };

        return (
            <ImageButton
                key={index}
                onClick={() => handleCharacterSelected(index)}
                iconSrc={data.iconUrl}
                altText={data.name}
                additionalButtonStyle={dynamicStyle}
            />
        );
    });

    // --  Character selection function
    const handleCharacterSelected = (id) => {
        if (id >= 0 && id < CHARACTER_DATA.length) {
            setselectedCharacter(CHARACTER_DATA[id]);
        } else {
            console.error('Invalid index:', id);
        }
    }


    return (
        <div className={styles.section} ref={sectionRef} style={{ height: `${1 * contentHeight}px` }}>
            <img className={styles.headtext} src={`${headtext}`} alt={'section-3'} />
            <div className={styles.characterShowcaseContainer}>
                <div className={styles.characterSelection}>
                    {characterButtonArray}
                </div>
                <img src={`https://sko-cdn.zoltangames.com/web/${selectedCharacter.name}.png`} alt="" className={styles.characterImage} />
                <div className={styles.characterSummaryContainer}>
                    <div className={styles.header}>
                        <img src={selectedCharacter.flagUrl} alt="" className={styles.flag} />
                        : {selectedCharacter.summaryHeader}
                    </div>
                    <div className={styles.subHeader} style={subHeaderframe}>
                        "{selectedCharacter.subHeader}"
                    </div>
                    <div className={styles.summaryContent}>
                        {selectedCharacter.summary}
                    </div>
                    
                    <IconVideoPlayer
                    cdn={cdn}
                        videoSrc={selectedCharacter.videoUrl}
                        thumbnailSrc={selectedCharacter.videoThumbnailUrl}
                        setIsVideoPlaying={setIsMenuShow}
                        thumbnailHeight='26%'
                        thumbnailWidth='46%'
                    ></IconVideoPlayer>
                </div>
            </div>
        </div>
    )
}

export default PcSection3;
