import styles from './PcSection1.module.scss';
import { ProgressBar, Step } from 'react-step-progress-bar';
import ImageButton from '../../components/Button';
import { useState, useEffect } from 'react';
import FollowReward from '../../components/FollowReward';
import apiHelper from '../../helper/apiHelper';
import handleGAevent from '../../helper/analytic';
import ReactPixel from 'react-facebook-pixel';
import { END_DATE, START_DATE } from '../../consts/dateData';
import PopUp from '../../components/PopUp';
import CDNBackground from '../../components/CDNBackground';

const PcSection1 = ({ sectionRef, contentHeight, preRegisCount, setIsMenuShow }) => {

    // -- Character and headtext
    const headtext = `https://sko-cdn.zoltangames.com/preregister/img-headtext.png`;
    const headtext2 = `https://sko-cdn.zoltangames.com/preregister/img-headtext-2-v2.png`;
    const char1 = `https://sko-cdn.zoltangames.com/preregister/img-char-1.png`;
    const char2 = `https://sko-cdn.zoltangames.com/preregister/img-char-2.png`;
    const char3 = `https://sko-cdn.zoltangames.com/preregister/img-char-3.png`;
    const char4 = `https://sko-cdn.zoltangames.com/preregister/img-char-4.png`;
    const rewardCheck = `https://sko-cdn.zoltangames.com/preregister/img-reward-check.png`;
    const popUpBg = `https://sko-cdn.zoltangames.com/preregister/bg-pop-up.png`;

    // -- Follow reward
    const followRewardContainer = {
        backgroundImage: `url(https://sko-cdn.zoltangames.com/preregister/bg-follow-reward.png)`
    };
    const followRewardData = [
        { id: 0, rewardText: 'ติดตามครบ', rewardQuantity: '2,500', infoText: 'ติดตามครบ 2,500 คน\nได้รับเหรียญทองแดง x50,000', iconUrl: 'img-reward-type-5.png' },
        { id: 1, rewardText: 'ติดตามครบ', rewardQuantity: '5,000', infoText: 'ติดตามครบ 5,000 คน\nได้รับชิ้นส่วนขุนพลเทพ x5', iconUrl: 'img-reward-type-3.png' },
        { id: 2, rewardText: 'ติดตามครบ', rewardQuantity: '7,500', infoText: 'ติดตามครบ 7,500 คน\nได้รับตำลึง x388', iconUrl: 'img-reward-type-1.png' },
        { id: 3, rewardText: 'ติดตามครบ', rewardQuantity: '10,000', infoText: 'ติดตามครบ 10,000 คน\nได้รับชิ้นส่วนขุนพลเทพ x10', iconUrl: 'img-reward-type-3.png' },
        { id: 4, rewardText: 'ติดตามครบ', rewardQuantity: '15,000', infoText: 'ติดตามครบ 15,000 คน\nได้รับกระดานพยากรณ์ x10', iconUrl: 'img-reward-type-2.png' },
    ]

    const followReward2Data = [
        { id: 0, rewardText: 'เข้าร่วมครบ', rewardQuantity: '500', infoText: 'เข้าร่วมครบ 500 คน\nได้รับเร่งความเร็ว x120', iconUrl: 'img-reward-type-4.png' },
        { id: 1, rewardText: 'เข้าร่วมครบ', rewardQuantity: '1000', infoText: 'เข้าร่วมครบ 1,000 คน\nได้รับชิ้นส่วนขุนพลเทพ x5', iconUrl: 'img-reward-type-3.png' },
        { id: 2, rewardText: 'เข้าร่วมครบ', rewardQuantity: '2000', infoText: 'เข้าร่วมครบ 2,000 คน\nได้รับตำลึง x388', iconUrl: 'img-reward-type-1.png' },
        { id: 3, rewardText: 'เข้าร่วมครบ', rewardQuantity: '3000', infoText: 'เข้าร่วมครบ 3,000 คน\nได้รับชิ้นส่วนขุนพลเทพ x10', iconUrl: 'img-reward-type-3.png' },
        { id: 4, rewardText: 'เข้าร่วมครบ', rewardQuantity: '4000', infoText: 'เข้าร่วมครบ 4,000 คน\nได้รับการ์ดอัปดาวขุนพลเทพ x1', iconUrl: 'img-reward-type-6.png' },
    ]

    const steps = [
        { text: "ลงทะเบียนครบ", number: "50,000", checkpoint: 49999},
        { text: "ลงทะเบียนครบ", number: "100,000", checkpoint: 99999 },
        { text: "ลงทะเบียนครบ", number: "200,000", checkpoint: 199999 },
        { text: "ลงทะเบียนครบ", number: "300,000", checkpoint: 299999 },
        { text: "ลงทะเบียนครบ", number: "400,000", checkpoint: 399999 },
    ];

    const rewards = [
        { iconSrc: "reward_1.png", altText: "Icon", text: "ประสบการณ์ขุนพล", text2: "x30,000", threshold: 0.1 },
        { iconSrc: "reward_2.png", altText: "Icon", text: "ยาทะลวงเลื่อนขั้น", text2: "x200", threshold: 25 },
        { iconSrc: "reward_3.png", altText: "Icon", text: "กระดานพยากรณ์", text2: "x10", threshold: 50 },
        { iconSrc: "reward_6.png", altText: "Icon", text: "ฉายา - พลังล้ำเลิศ", text2: "x1", threshold: 75 },
        { iconSrc: `reward_7.png`, altText: "Icon", text: "ขุนพลระดับ SSR*", text2: "x1 / ตำลึง x888", threshold: 99 },
    ];



    // -- Progress bar
    const [registerPercentage, setRegisterPercentage] = useState(0);

    // -- Character timer
    const [initialTimestamp, setInitialTimestamp] = useState(new Date());
    const [characterTimerValue, setCharacterTimerValue] = useState(0);

    // -- Info Popup state
    const [isInfoPopupShow, setIsInfoPopupShow] = useState(false);

    // -- Progress bar style
    const rewardStyle = {
        width: '75%',
    };
    const progressBarStyle = {
        width: '80%',
    }

    // -- Follow button style
    const followBtnStyle = {
        width: '28.5rem',
        height: '100%'
    }

    const infoButton2Style = {
        height: '3.5rem',
        width: '3.5rem',
        position: 'absolute',
        top: '12.2%',
        right: '36%'
    };

    const containerStyleInfo = {
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        color: '#79694D',
        fontFamily: 'Noto Sans Thai',
        fontWeight: '600',
        whiteSpace: 'pre-line',
        marginLeft: '10%',
        fontSize: '1.7rem'
    }


    // -- Follow facebook page 
    const generateDeviceId = () => {
        return Math.random().toString(36).substr(2, 9);
    };
    const handleClickFollowButton = (identifier) => {
        let hasClickedBefore;
        try {
            hasClickedBefore = localStorage.getItem('hasClicked ' + identifier);
        } catch (error) {
            console.error('Error accessing localStorage:', error);
        }
        if (!hasClickedBefore) {
            try {
                localStorage.setItem('hasClicked ' + identifier, 'true');
                const deviceId = localStorage.getItem('deviceId' + identifier);
                if (!deviceId) {
                    // Generate a unique device identifier
                    const newDeviceId = generateDeviceId();
                    // Store the device identifier in localStorage
                    localStorage.setItem('deviceId' + identifier, newDeviceId);
                    const addCount = async (identifier) => {
                        try {
                            const body = { type: identifier }
                            const result = await apiHelper('milestone/count', 'POST', body);
                        } catch (error) {

                        }
                    };
                    addCount(identifier);
                }
            } catch (error) {
                console.error('Error accessing localStorage:', error);
            }
        }
        if (identifier === 1) {
            handleGAevent('Page-2', 'Click_Follow_Facebook_Fanpage', `Click Follow Facebook Fanpage`);
            ReactPixel.trackCustom('ClickFollowFacebookFanpage');
            const facebookPageLink = 'https://www.facebook.com/Samkok.Origin';
            window.open(facebookPageLink, '_blank');
        } else {
            handleGAevent('Page-2', 'Click_Follow_Facebook_Group', `Click Follow Facebook Official Group`);
            ReactPixel.trackCustom('ClickFollowFacebookGroup');
            const discordInviteLink = 'https://facebook.com/groups/samkokoriginofficialgroup/';
            window.open(discordInviteLink, '_blank');
        }
    };

    // -- Info button function
    const handleInfoPopupOpen = () => {
        setIsInfoPopupShow(true);
        setIsMenuShow(false);
        handleGAevent('Page-2', 'Open_Info_Pop_Up_Page_2', `Open Info Pop Up Page 2`);
        ReactPixel.trackCustom('OpenInfoPopUpPage2');
    }
    const handleInfoPopupClose = () => {
        setIsInfoPopupShow(false);
        setIsMenuShow(true);
        handleGAevent('Page-3', 'Close_Info_Pop_Up_Page_2', `Close Info Pop Up Page 2`);
        ReactPixel.trackCustom('CloseInfoPopUpPage3');
    }
    // -- Count pre regis percentage

    // const calculatePercentage = (preRegisCount) => {
    //     const maxCheckpoint = steps[steps.length - 1].checkpoint;
    //     if (preRegisCount <= steps[0].checkpoint) {
    //         return 0;
    //     } else if (preRegisCount >= maxCheckpoint) {
    //         return 100;
    //     } else {
    //         const stepRange = 100 / (steps.length - 1);
    //         for (let i = 0; i < steps.length - 1; i++) {
    //             if (preRegisCount >= steps[i].checkpoint && preRegisCount < steps[i + 1].checkpoint) {
    //                 const progressInRange = ((preRegisCount - steps[i].checkpoint) / (steps[i + 1].checkpoint - steps[i].checkpoint)) * stepRange;
    //                 return Math.ceil((i * stepRange) + progressInRange);
    //             }
    //         }
    //     }
    // };

    const calculatePercentage = (preRegisCount) => {
        const steps = [50000, 100000, 200000, 300000, 400000, 500000];
        const stepPercentages = [0, 25, 50, 75, 100];
      
        if (preRegisCount <= steps[0]) {
            return 0;
        } else if (preRegisCount >= steps[steps.length - 1]) {
            return 100;
        }
        
        for (let i = 1; i < steps.length; i++) {
            if (preRegisCount <= steps[i]) {
                const stepRange = steps[i] - steps[i - 1];
                const valueInRange = preRegisCount - steps[i - 1];
                const percentageInStep = (valueInRange / stepRange) * 25;
                return Math.floor(stepPercentages[i - 1] + percentageInStep);
            }
        }
      
        return 100; // In case the value exceeds the highest step
    };
    useEffect(() => {
        const percentage = calculatePercentage(preRegisCount);
        console.log(percentage);
        console.log(preRegisCount);
        setRegisterPercentage(percentage);

    }, [preRegisCount]);

    // -- Character countdown timer
    useEffect(() => {
        // Get the current date and time
        const currentDate = new Date();

        // Get the timestamp for 24 hours ago from the initial timestamp
        const twentyFourHoursAgo = new Date(initialTimestamp);
        twentyFourHoursAgo.setDate(twentyFourHoursAgo.getDate() + 1); // Add 1 day to get the timestamp for 24 hours ago

        // Check if the current date is after 24 hours ago
        const isAfter24Hours = currentDate > twentyFourHoursAgo;

        const handleTimer = () => {
            // If it's after 24 hours, exclude reward_7_3
            if (isAfter24Hours) {
                setCharacterTimerValue((prevValue) => (prevValue + 1) % 3);
            } else {
                setCharacterTimerValue((prevValue) => (prevValue + 1) % 4);
            }
        };

        const timerInterval = setInterval(handleTimer, 60000);

        return () => clearInterval(timerInterval);
    }, []);

    // -- Follow reward array
    const followReward1 = followRewardData.map((data, index) => {
        return (
            <FollowReward
                key={index}
                iconUrl={data.iconUrl}
                onClose={() => { }}
                rewardText={data.rewardText}
                rewardQuantity={data.rewardQuantity}
                infoText={data.infoText}
            ></FollowReward>
        );
    });
    const followReward2 = followReward2Data.map((data, index) => {
        return (
            <FollowReward
                key={index}
                iconUrl={data.iconUrl}
                onClose={() => { }}
                rewardText={data.rewardText}
                rewardQuantity={data.rewardQuantity}
                infoText={data.infoText}
            ></FollowReward>
        );
    });

    return (
        <div className={styles.section} ref={sectionRef} style={{ height: `${2 * contentHeight}px` }}>
            <PopUp isShow={isInfoPopupShow} handleClosePopup={handleInfoPopupClose} containerStyle={containerStyleInfo} popoutBackgroundUrl={popUpBg}>
                ระยะเวลากิจกรรม <br />
                เริ่มต้น : {START_DATE}<br />
                สิ้นสุด : {END_DATE}<br />
                ขั้นตอนการชวนเพื่อน<br />
                <br />

                รายละเอียดกิจกรรม<br />
                ลงทะเบียนล่วงหน้าผ่าน E-mail เท่านั้น<br />
                การลงทะเบียนล่วงหน้าจะสามารถรับรางวัลได้ลำดับละ 1 ครั้งเท่านั้นเมื่อผ่านเงื่อนไข<br />
                รางวัลสำหรับการลงทะเบียนล่วงหน้า จะได้รับเฉพาะผู้ที่ลงทะเบียนล่วงหน้าเท่านั้น<br />
                หากจำนวนลงทะเบียนล่วงหน้าครบตามเป้าหมายในแต่ละขั้นจะได้รับไอเทมเพิ่มเติม<br />
                ทีมงานขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า<br />
            </PopUp>
            <img className={styles.headtext} src={`${headtext}`} alt={'section-1'} />
            <ImageButton onClick={handleInfoPopupOpen} iconSrc="btn-info.png" altText="Icon" additionalButtonStyle={infoButton2Style} />
            <div className={styles.characterContainer}>
                {/* <img className={styles.character} src={`${char1}`} alt={'char-1'} />
                <img className={styles.character} src={`${char2}`} alt={'char-2'} />
                <img className={styles.character} src={`${char3}`} alt={'char-3'} />
                <img className={styles.character} src={`${char4}`} alt={'char-4'} /> */}
                <div className={styles.character}>
                    <CDNBackground cdnLink={`https://sko-cdn.zoltangames.com/preregister/vid-char-1.webm`} poster={char1} isContain={true} hoverPlay={true}></CDNBackground>
                </div>
                <div className={styles.character}>
                    <CDNBackground cdnLink={`https://sko-cdn.zoltangames.com/preregister/vid-char-2.webm`} poster={char2} isContain={true} hoverPlay={true}></CDNBackground>
                </div>
                <div className={styles.character}>
                    <CDNBackground cdnLink={`https://sko-cdn.zoltangames.com/preregister/vid-char-3.webm`} poster={char3} isContain={true} hoverPlay={true}></CDNBackground>
                </div>
                <div className={styles.character}>
                    <CDNBackground cdnLink={`https://sko-cdn.zoltangames.com/preregister/vid-char-4.webm`} poster={char4} isContain={true} hoverPlay={true}></CDNBackground>
                </div>
            </div>
            <div className={styles.progressCompleteContainer}>
                <div className={styles.progressContainer}>
                    <div style={progressBarStyle}>
                        <br />
                        <ProgressBar percent={registerPercentage} filledBackground="#8C2C23" height={3}>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    {({ accomplished }) => (
                                        <div className={`${styles.indexedStep} ${accomplished ? styles.accomplished : ''}`}>
                                            <div className={styles.label}>
                                                <div>{step.text}</div>
                                                <div>{step.number}</div>
                                            </div>
                                        </div>
                                    )}
                                </Step>
                            ))}
                        </ProgressBar>
                    </div>
                </div>
                <div className={styles.progressContainer}>
                    {rewards.map((reward, index) => (
                        <div key={index} className={styles.reward}>
                            <ImageButton iconSrc={index === rewards.length - 1 ? `reward_7_${characterTimerValue}.png` : reward.iconSrc} altText={reward.altText} additionalButtonStyle={rewardStyle} />
                            <img className={`${styles.rewardCheck} ${registerPercentage <= reward.threshold ? styles.hidden : ''}`} src={`${rewardCheck}`} alt={'reward-check'} />
                            <div className={styles.rewardName}>
                                <div>{reward.text}</div>
                                <div>{reward.number}</div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
            <img className={styles.headtext} style={{ marginTop: '5%', marginBottom: '3%' }} src={`${headtext2}`} alt={'section-1'} />
            <div className={styles.followRewardContainer} style={followRewardContainer}>
                {followReward1}
            </div>
            <div className={styles.followRewardContainer} style={followRewardContainer}>
                {followReward2}
            </div>
            <div className={styles.btnContainer}>
                <ImageButton onClick={() => handleClickFollowButton(1)} iconSrc="btn-follow-discord.png" altText="Icon" additionalButtonStyle={followBtnStyle} />
                <ImageButton onClick={() => handleClickFollowButton(2)} iconSrc="btn-follow-facebook-group.png" altText="Icon" additionalButtonStyle={followBtnStyle} />
            </div>
        </div>
    )
}

export default PcSection1;
