import { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

import HamburgerMenuMobile from '../../components/HamburgerMenuMobile';
import handleGAevent from '../..//helper/analytic';
import apiHelper from '../../helper/apiHelper';
import PopUpFullTeam from '../../components/PopUpFullTeam';

import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';

import styles from './MobilePreRegister.module.scss';
import { AOS_STORE_LINK, IOS_STORE_LINK } from '../../consts/StoreLink';

const MobilePreRegister = ({ cdn, isMobile }) => {

  // --- Navbar

  const imgMenuBar = cdn + 'img-menu-bar' + (isMobile ? '' : '-tablet') + '.png';
  const btnLogo = cdn + 'btn-logo.png';
  const btnHamburger = cdn + 'btn-hamburger.png';
  const containerRef = useRef(null);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const contentWrapperRef = useRef(null);
  const [contentWidth, setContentWidth] = useState(0);
  const [activeTab, setActiveTab] = useState('section1');
  const [isHambugerMenuShow, setIsHamburgerMenuShow] = useState(false);

  // --- Navbar: Logo

  const navigate = useNavigate();

  function handleLogoClick() {
    navigate("/");
  }

  // --- Navbar: Hamburger

  const handleHamburgerMenuShowOpen = () => {
    handleGAevent('Hamburger-Menu', 'Open_Hamburger_Menu', 'Open Hamburger Menu');
    ReactPixel.trackCustom('OpenHamburgerMenu');
    setIsHamburgerMenuShow(true);
  }

  const handleHamburgerMenuShowClose = () => {
    handleGAevent('Hamburger-Menu', 'Close_Hamburger_Menu', 'Close Hamburger Menu');
    ReactPixel.trackCustom('CloseHamburgerMenu');
    setIsHamburgerMenuShow(false);
  }

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const handleLinkClick = (ref, tabId) => {
    scrollToRef(ref);
    handleHamburgerMenuShowClose();
  };

  const openExternalLink = (url) => {
    if (url) {
      const newWindow = window.open(url, '_blank');
      if (newWindow) {
        newWindow.focus();
      } else {
        console.error('Failed to open new window/tab.');
        window.location.href = url;
      }
    }
  };

  const handleJoinFanpage = () => {
    handleGAevent('Page-1', 'Click_Join_Facebook_Group', `Click Join Facebook Group`);
    ReactPixel.trackCustom('ClickJoinFacebookGroup');
    const link = 'https://www.facebook.com/groups/samkokoriginofficialgroup';
    openExternalLink(link);
  };

  const handleJoinDiscord = () => {
    handleGAevent('Page-1', 'Click_Join_Discord', `Click Join Discord`);
    ReactPixel.trackCustom('ClickJoinDiscord');
    const link = 'https://discord.gg/UzubFYuUfx';
    openExternalLink(link);
  };

  const handleJoinFacebook = () => {
    handleGAevent('Page-1', 'Click_Join_Facebook_Fanpage', `Click Join Facebook Fanpage`);
    ReactPixel.trackCustom('ClickJoinFacebookFanpage');
    const link = 'https://www.facebook.com/Samkok.Origin';
    openExternalLink(link);
  };

  const overlayMenuFunctionArray = [
    () => handleLinkClick(section1Ref, 'section1'),
    () => handleLinkClick(section2Ref, 'section2'),
    () => handleLinkClick(section3Ref, 'section3'),
    () => handleLinkClick(section4Ref, 'section4'),
    () => handleLinkClick(section5Ref, 'section5'),
    () => handleJoinFanpage(),
    () => handleJoinDiscord(),
    () => handleJoinFacebook(),
    () => window.location.href = "/term-of-service",
    () => window.location.href = "/privacy-policy",
    () => window.open("https://zoltan-help.freshdesk.com/th/support/home", "_blank")
  ];

  useEffect(() => {
    const onScroll = () => {
      if (Math.floor(section5Ref.current.getBoundingClientRect().top) <= 0) {
        if (activeTab !== 'section5')
          setActiveTab('section5');
      }
      else if (Math.floor(section4Ref.current.getBoundingClientRect().top) <= 0) {
        if (activeTab !== 'section4')
          setActiveTab('section4');
      }
      else if (Math.floor(section3Ref.current.getBoundingClientRect().top) <= 0) {
        if (activeTab !== 'section3')
          setActiveTab('section3');
      }
      else if (Math.floor(section2Ref.current.getBoundingClientRect().top) <= 0) {
        if (activeTab !== 'section2')
          setActiveTab('section2');
      }
      else if (Math.floor(section1Ref.current.getBoundingClientRect().top) <= 0) {
        if (activeTab !== 'section1')
          setActiveTab('section1');
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [activeTab]);

  // --- Sticky Store

  const isAndroid = /Android/.test(navigator.userAgent);
  const imgStore = cdn + (isAndroid ? 'btn-playstore.png' : 'btn-appstore.png');
  // const imgStore = cdn + (isAndroid ? 'btn-playstore.svg' : 'btn-appstore-mobile-v4.svg');
  const urlStore = (isAndroid ? AOS_STORE_LINK : IOS_STORE_LINK);


  const handleStoreClick = () => {
    handleGAevent('Footer', 'Click_Badge', 'Click Playstore/Appstore Badge for mobile');
    ReactPixel.trackCustom('ClickBadge');
    if (urlStore !== '') {
      window.open(urlStore, '_blank');
    }
  }

  // --- Resize

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContentWidth(containerRef.current.offsetWidth);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // --- Data

  const [preRegisCount, setPreRegisCount] = useState(0);
  const [email, setEmail] = useState('');
  const [rewardAccepted, setRewardAccepted] = useState(0);
  const [isTeamCreator, setIsTeamCreator] = useState(0);

  const fetchEmail = async () => {
    try {
      const storedEmail = localStorage.getItem('email');
      if (storedEmail) {
        setEmail(storedEmail);
      }
    } catch (error) {
      console.error('Error accessing localStorage:', error);
    }
  }

  const fetchDataReward = async () => {
    try {
      const body = { email: email };
      const result = await apiHelper('users/count-reward', 'POST', body);

      const responseData = result.data.count;
      setRewardAccepted(responseData);
      setIsTeamCreator(result.data.isCreator);
    } catch (error) {

    }
  }

  const fetchData = async () => {
    try {
      const result = await apiHelper('users/count-user', 'GET');
      const responseData = result.data.count;
      setPreRegisCount(() => (responseData));
    } catch (error) {
      setPreRegisCount((prevValue) => (prevValue));
    }
  };

  // -- Team Full state
  const [isTeamFullPageShow, setIsTeamFullPageShow] = useState(false);

  useEffect(() => {
    const currentUrl = window.location.href;
    const queryString = currentUrl.split('?')[1];
    const queryParams = new URLSearchParams(queryString);
    const invite = queryParams.get('invite');
    if (invite) {
      const fetchTeamData = async () => {
        try {
          const result = await apiHelper('users/check-team-status', 'GET', null, { team: invite });
          if (result.responseCode === 1 || result.responseCode === 4) {
            setIsTeamFullPageShow(true);
          }
        } catch (error) {
          setIsTeamFullPageShow(true);

        }
      };
      fetchTeamData();
    }
  }, []);
  useEffect(() => {
    fetchEmail();
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataReward = async () => {
      try {
        const body = { email: email };
        const result = await apiHelper('users/count-reward', 'POST', body);

        const responseData = result.data.count;
        setRewardAccepted(responseData);
        setIsTeamCreator(result.data.isCreator);
      } catch (error) {

      }
    }
    if (email) {
      fetchDataReward();
    }
  }, [email]);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    const handleBodyScroll = () => {
      if (isTeamFullPageShow) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    };
  
    handleBodyScroll();
  
    window.addEventListener('resize', handleBodyScroll);
    return () => {
      window.removeEventListener('resize', handleBodyScroll);
    };
  }, [isTeamFullPageShow]);

  return (
    <div ref={containerRef}>
      <div className={styles.contentWrapper} ref={contentWrapperRef}>
        <PopUpFullTeam cdn={cdn} isShow={isTeamFullPageShow}></PopUpFullTeam>
        <Section1
          cdn={cdn}
          sectionRef={section1Ref}
          isMobile={isMobile}
          contentWidth={contentWidth}
          fetchDataReward={fetchDataReward}
          preRegisCount={preRegisCount}
          email={email}
          setEmail={setEmail}
          rewardAccepted={rewardAccepted}
          setRewardAccepted={setRewardAccepted}
          isTeamCreator={isTeamCreator}
          setIsTeamCreator={setIsTeamCreator} />
        <Section2
          cdn={cdn}
          sectionRef={section2Ref}
          isMobile={isMobile}
          preRegisCount={preRegisCount} />
        <Section3
          cdn={cdn}
          sectionRef={section3Ref}
          isMobile={isMobile}
          fetchDataReward={fetchDataReward}
          email={email}
          setEmail={setEmail}
          rewardAccepted={rewardAccepted}
          setRewardAccepted={setRewardAccepted}
          isTeamCreator={isTeamCreator}
          setIsTeamCreator={setIsTeamCreator} />
        <Section4
          cdn={cdn}
          sectionRef={section4Ref}
          isMobile={isMobile}
          contentWidth={contentWidth} />
        <Section5
          cdn={cdn}
          sectionRef={section5Ref}
          isMobile={isMobile}
          contentWidth={contentWidth} />
      </div>
      <div className={styles.menuBarContainer}>
        <img className={styles.menuBar} src={`${imgMenuBar}`} alt={'img-menu-bar'} />
        <img className={styles.btnLogo} src={`${btnLogo}`} alt={'btn-logo.png'} onClick={handleLogoClick} />
        <img className={styles.btnHamburger} src={`${btnHamburger}`} alt={'btn-hamburger'} onClick={handleHamburgerMenuShowOpen} />
      </div>
      <HamburgerMenuMobile cdn={cdn} activeTab={activeTab} isShow={isHambugerMenuShow} functionArray={overlayMenuFunctionArray} handleClosePopup={handleHamburgerMenuShowClose} />
      {/* <img className={styles.btnStore} src={imgStore} alt={'btn-store'} onClick={handleStoreClick} /> */}
    </div>
  );
}

export default MobilePreRegister;
